window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');
require('bootstrap');

const $navbar = $('.navbar')

function checkScroll() {
    if($(window).scrollTop() > 0) {
        $navbar
            .addClass("bg-white shadow-sm navbar-light")
            .removeClass('navbar-dark')
    } else {
        $navbar
            .removeClass("bg-white shadow-sm navbar-light")
            .addClass('navbar-dark')
    }
}

if ($navbar.length > 0) {
    $(window).on("scroll load resize", function(){
        checkScroll();
    });
}

$('[data-scrollTo]').click(function (e) {
    e.preventDefault()

    const $target = $($(this).attr('data-scrollTo'))

    $('html, body').animate({
        scrollTop: $target.offset().top
    })
});
